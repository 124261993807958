import {
  Box,
  Button,
  useBoolean,
  VStack,
  Alert,
  AlertIcon,
  Progress,
  useToast
} from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { apiClient } from 'v2/services/clients/apiClient'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { axiosWebsite } from 'helpers/axiosInstances'
import { showLoading, setGlobalMessage, clearLoading } from 'redux/actions'

import {
  formatCNPJ,
  formatCPF,
  formatColumn,
  formatDate,
  formatProduct
} from 'helpers/formats'
import { isUnauthorized } from 'helpers/errors'
import { validateFild } from 'helpers/validFilds'
import { getMoeda, getMoedaCompleto } from 'helpers/moedas'
import { Permissions } from 'interfaces/web/permission'

const ListReportsPage: React.FC = () => {
  const { user, hasPermissions, isAutorizado } = useGlobals()

  const permDIRF = isAutorizado
    ? hasPermissions([Permissions.REPORT_DIRF])
    : true
  const permPAGREC = isAutorizado
    ? hasPermissions([Permissions.REPORT_PAGREG])
    : true
  const permECF = isAutorizado ? hasPermissions([Permissions.REPORT_ECF]) : true
  const permVOTORANTIM = isAutorizado
    ? hasPermissions([Permissions.REPORT_VOTORANTIM])
    : true
  const permIMPEXP = isAutorizado
    ? hasPermissions([Permissions.REPORT_IMPEXP])
    : true
  const permIR = isAutorizado ? hasPermissions([Permissions.REPORT_IR]) : true
  const permNF = isAutorizado ? hasPermissions([Permissions.REPORT_NF]) : true
  const permTRF = isAutorizado ? hasPermissions([Permissions.REPORT_TRF]) : true
  const permVCONT = isAutorizado
    ? hasPermissions([Permissions.REPORT_VOLCONTRACT])
    : true

  const [hidden, setDevHidden] = useState<boolean>(true)
  const dispatch = useDispatch()
  const history = useHistory()
  const { hashData, redirect } = useHashData()
  const [isLoading, loading] = useBoolean()
  const [mode, setMode] = useState<
    | 'one'
    | 'two'
    | 'three'
    | 'four'
    | 'five'
    | 'six'
    | 'seven'
    | 'eight'
    | 'nine'
    | 'ten'
  >('one')
  const [modeObj, setModeObj] = useState<any>({ select: 'one' })
  const toast = useToast()

  useEffect(() => {
    const date = new Date().toISOString().split('T')[0]
    redirect({
      customer: user.permission_group_company?.includes(
        Number(user.id_syscambio)
      )
        ? {
            corporate_name:
              user.cad_type === 'AUTORIZADO'
                ? user.company_name
                : user.user_name,
            document:
              user.cad_type === 'AUTORIZADO'
                ? user.company_document
                : user.document,
            id_syscambio: user.id_syscambio
          }
        : undefined,
      start_date: date,
      end_date: date
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function comboList () {
    const list = [
      {
        label: () => 'Pagadores e Recebedores',
        value: 'one',
        view: permPAGREC
      },
      {
        label: () => 'Notas Fiscais',
        value: 'two',
        view: permNF
      },
      {
        label: () => 'Informe de Redimentos',
        value: 'four',
        view: permIR
      },
      {
        label: () => 'DIRF',
        value: 'seven',
        view: permDIRF
      },
      {
        label: () => 'Pagamento Antecipado – Importação/Exportação.',
        value: 'five',
        view: permIMPEXP
      },
      {
        label: () => 'Volumetria de Contratos',
        value: 'six',
        view: permVCONT
      },
      {
        label: () => 'ECF Y520',
        value: 'eight',
        view: permECF
      },
      {
        label: () => 'Operações',
        value: 'nine',
        view: permVOTORANTIM
      },
      {
        label: () => 'Tributos de Remessas Financeiras',
        value: 'ten',
        view: permTRF
      }
    ]

    return list.filter((e) => e.view === true)
  }

  async function exportPayerReceiver (id_syscambio: number | string) {
    if (!id_syscambio) {
      toast({
        title: 'Filtro',
        description: 'Todos os campos da consulta são necessarios!',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
      return null
    }

    loading.on()
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const list = (await apiClient.payer.all(id_syscambio)) ?? []

    if (list.length === 0) {
      toast({
        title: 'Retorno',
        description: 'Sem dados para o filtro selecionado!',
        status: 'info',
        duration: 3000,
        isClosable: true
      })
      return null
    }

    const final = list?.map(function (e) {
      return {
        'Cod. PagReg': e.CodPagReg,
        'Beneficiário Rel. Vínculo.':
          e.BeneRelVinculo === 50
            ? 'DEMAIS'
            : e.BeneRelVinculo === 40
            ? 'OPERAÇÃO ENTRE EMPRESAS DO MESMO GRUPO ECONÔMICO'
            : e.BeneRelVinculo === 67
            ? 'CLASSIFICAÇÃO NÃO REQUERIDA PELA REGULAMENTAÇÃO'
            : '',
        'Nome do Beneficiário': e.NomeBeneficiario,
        'Identificação do Beneficiário': e.PagadorRecebedorIdentificacao,
        NIF: e.Nif,
        'Endereço do Beneficiário': '', //e.EnderecoBeneficiario,
        'Pais Beneficiário': e.PaisBeneficiario,
        'Cidade do Beneficiário': e.CidadeBeneficiario,
        'Estado do Beneficiário': e.EstadoBeneficiario,
        'Nome do Banco Beneficiário': e.NomeBancoBeneficiario,
        'Endereço do Banco Beneficiário': e.EnderecoBancoBeneficiario,
        'Pais do Banco Beneficiário': e.PaisBancoBeneficiario,
        'Cidade Banco Beneficiário': e.CidadeBancoBeneficiario,
        'Swift Banco Beneficiário': e.SwiftBancoBeneficiario,
        'Conta do Banco Beneficiário': e.ContaBancoBeneficiario,
        'Iban Banco Beneficiário': e.IbanBancoBeneficiario,
        'Branch Beneficiário': e.BranchBeneficiario,
        'CNAPS China': e.CnapsBeneficiarioChina,
        'Sub Branch Beneficiário': e.SubBranchBeneficiario,
        'Aba do Banco Beneficiario': e.AbaBancoBeneficiario,
        'SortCode Inglaterra': e.SortCodeInglaterra,
        'BLZ Alemanha': e.BlzAlemanha,
        'BranchCode Africa do sul': e.BranchCodeAfricadosul,
        'BSB Australia': e.BsbAustralia,
        'Transit Canadá': e.TransitCanada,
        FutureCredit: e.FutureCredit,
        DetailCharge: e.DetailCharge,
        'Nome do Intermediário': e.NomeIntermediario,
        'End. do Banco Intermediário': e.EndBancoIntermediario,
        'Cidade do Banco Intermediário': e.CidadeBancoIntermediario,
        'País do Banco Intermediário': e.PaisBancoIntermediario,
        'Conta Intermediário': e.ContaIntermediario,
        'Swift Intermediário': e.SwiftIntermediario,
        'ABA Intermediário': e.AbaIntermediario,
        'IBAN Intermediário': e.IbanIntermediario,
        'CHIPS Intermediário': e.ChipsIntermediario,
        CodCademp: e.CodCademp,
        Observação: e.Observacao
      }
    })
    const ws = XLSX.utils.json_to_sheet(final)
    const keys = Object.keys(list[0])
    let objectMaxLength: any[] = []
    for (let i = 0; i < final.length; i++) {
      let value: any = Object.values(final[i])
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == 'number') {
          objectMaxLength[j] = 15
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j]?.length
              ? objectMaxLength[j]
              : value[j]?.length >= 15
              ? value[j]?.length + 1
              : 15
        }
      }
    }
    const colConfig: any[] = [
      { width: Number(objectMaxLength[0]) },
      { width: Number(objectMaxLength[1]) },
      { width: Number(objectMaxLength[2]) },
      { width: Number(objectMaxLength[3]) },
      { width: Number(objectMaxLength[4]) },
      { width: Number(objectMaxLength[5]) },
      { width: Number(objectMaxLength[6]) },
      { width: Number(objectMaxLength[7]) },
      { width: Number(objectMaxLength[8]) },
      { width: Number(objectMaxLength[9]) },
      { width: Number(objectMaxLength[10]) },
      { width: Number(objectMaxLength[11]) },
      { width: Number(objectMaxLength[12]) },
      { width: Number(objectMaxLength[13]) },
      { width: Number(objectMaxLength[14]) },
      { width: Number(objectMaxLength[15]) },
      { width: Number(objectMaxLength[16]) },
      { width: Number(objectMaxLength[17]) },
      { width: Number(objectMaxLength[18]) },
      { width: Number(objectMaxLength[19]) },
      { width: Number(objectMaxLength[20]) },
      { width: Number(objectMaxLength[21]) },
      { width: Number(objectMaxLength[22]) },
      { width: Number(objectMaxLength[23]) },
      { width: Number(objectMaxLength[24]) },
      { width: Number(objectMaxLength[25]) },
      { width: Number(objectMaxLength[26]) },
      { width: Number(objectMaxLength[27]) },
      { width: Number(objectMaxLength[28]) },
      { width: Number(objectMaxLength[29]) },
      { width: Number(objectMaxLength[30]) },
      { width: Number(objectMaxLength[31]) },
      { width: Number(objectMaxLength[32]) },
      { width: Number(objectMaxLength[33]) },
      { width: Number(objectMaxLength[34]) },
      { width: Number(objectMaxLength[35]) },
      { width: Number(objectMaxLength[36]) },
      { width: Number(objectMaxLength[37]) }
    ]
    keys.forEach((e) => colConfig.push({ wch: e.length }))
    ws['!cols'] = colConfig
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'PagadorRecebedor' + fileExtension)
    loading.off()
  }
  async function exportFiscalNotes (params: any) {
    try {
      if (
        !validateFild(hashData, [
          'customer',
          'note_type',
          'data_type',
          'start_date',
          'end_date'
        ])
      ) {
        toast({
          title: 'Filtro',
          description: 'Todos os campos da consulta são necessarios!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
        return null
      }

      loading.on()
      const param = {
        id_syscambio: params.customer.id_syscambio,
        note_type: params.note_type,
        data_type: params.data_type,
        start_date: params.start_date,
        end_date: params.end_date
      }
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const list: any[] = await apiClient.fiscalNotes.all(param)
      if (list.length === 0) {
        toast({
          title: 'Retorno',
          description: 'Sem dados para o filtro selecionado!',
          status: 'info',
          duration: 3000,
          isClosable: true
        })
        return null
      }
      const ws = XLSX.utils.json_to_sheet(
        list?.map(function (e) {
          return {
            'Número da Nota': e.NumeroNota,
            'TP. Nota': e.TipoNota === 'C' ? 'Corretagem' : 'Serviço',
            Cliente: e.NomCli,
            'Data Emissão': formatDate(e.DataEmissao),
            'Data Vencimento': formatDate(e.DataVencimento),
            'Valor Nota': e.ValorNota,
            'Valor IR': e.ValorIr
          }
        })
      )
      const keys = Object.keys(list[0])
      const colConfig: any[] = [
        { wch: 15 },
        { wch: 10 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 }
      ]
      keys.forEach((e) => colConfig.push({ wch: e.length }))
      ws['!cols'] = colConfig
      formatColumn(ws, 4, '#,##0.00')
      formatColumn(ws, 5, '#,##0.00')
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, 'NotasFiscais' + fileExtension)
    } catch (error) {
    } finally {
      loading.off()
    }
  }
  async function callIncomeReport () {
    try {
      if (hidden === true) {
        setDevHidden(false)
      }

      if (!validateFild(hashData, ['customer', 'type_inf', 'base'])) {
        return toast({
          title: 'Filtro',
          description: 'Todos os campos da consulta são necessarios!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      const body: any = {
        Name: 'queue_informe_rendimentos_direct',
        Priority: 'Normal',
        Reference: `${hashData?.customer?.id_syscambio} - ${
          new Date()?.toLocaleString('pt-BR', { timeZone: 'UTC' }).split(',')[0]
        }`,
        SpecificContent: {
          modelo_informe_rendimentos: hashData.type_inf,
          ano_base: hashData.base,
          data_geracao: new Date()
            ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
            .split(',')[0],
          cliente: hashData?.customer?.id_syscambio,
          ordem: 'NOME DO CLIENTE',
          email_solicitante: user.email //'paulo@digitaly.tech'
        }
      }

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite.post(`/queue`, body, config)

      toast({
        title: 'Solicitação',
        description:
          'Sua solicitação foi envia, por favor, aguarde o retorno por e-mail!',
        status: 'success',
        duration: 10000,
        isClosable: true
      })
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  async function callImpExp () {
    try {
      if (hidden === true) {
        setDevHidden(false)
      }

      if (
        !validateFild(hashData, [
          'customer',
          'type',
          'type_export',
          'start_date',
          'end_date'
        ])
      ) {
        return toast({
          title: 'Filtro',
          description: 'Todos os campos da consulta são necessarios!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      const body: any = {
        Name:
          hashData.type === 'imp' ? 'importacao_direct' : 'exportacao_direct',
        Priority: 'Normal',
        Reference: `${hashData?.customer?.id_syscambio} - ${
          new Date()?.toLocaleString('pt-BR', { timeZone: 'UTC' }).split(',')[0]
        }`,
        SpecificContent: {
          pdf_csv: hashData.type_export,
          periodo_a: new Date(hashData.start_date)
            ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
            .split(',')[0],
          periodo_b: new Date(hashData.end_date)
            ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
            .split(',')[0],
          cod_cliente: hashData?.customer?.id_syscambio,
          email_solicitante: user.email //'paulo@digitaly.tech'
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite.post(`/queue`, body, config)

      toast({
        title: 'Solicitação',
        description:
          'Sua solicitação foi envia, por favor, aguarde o retorno por e-mail!',
        status: 'success',
        duration: 10000,
        isClosable: true
      })
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  async function callVolumatric () {
    try {
      if (hidden === true) {
        setDevHidden(false)
      }

      if (!validateFild(hashData, ['customer', 'start_date', 'end_date'])) {
        return toast({
          title: 'Filtro',
          description: 'Todos os campos da consulta são necessarios!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body: any = {
        document: hashData.customer.document,
        start_date: hashData.start_date,
        end_date: hashData.end_date
      }

      const rest = await axiosWebsite.post(
        `/operation/volumetric`,
        body,
        config
      )

      if (rest?.data.length === 0) {
        toast({
          title: 'Retorno',
          description: 'Sem dados para o filtro selecionado!',
          status: 'info',
          duration: 3000,
          isClosable: true
        })
        return null
      }

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const ws = XLSX.utils.json_to_sheet(
        rest?.data?.map(function (e: any) {
          return {
            Empresa: e.client_name,
            CNPJ: formatCNPJ(e.client_document),
            Modalidade: formatProduct(e.product_type),
            Quantidade: e.quant,
            Moeda: getMoeda(e.currency_code),
            'Valor Total ME': Number(e.operation_value),
            'Valor Total Reais': Number(e.reais_value)
          }
        })
      )

      const colConfig: any[] = [
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 }
      ]
      ws['!cols'] = colConfig
      formatColumn(ws, 5, '#,##0.00')
      formatColumn(ws, 6, '#,##0.00')
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, 'Volumetria' + fileExtension)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  async function callDirf () {
    try {
      if (hidden === true) {
        setDevHidden(false)
      }

      if (!validateFild(hashData, ['customer', 'month', 'base'])) {
        return toast({
          title: 'Filtro',
          description: 'Todos os campos da consulta são necessarios!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const body: any = {
        document: hashData.customer.document,
        month: hashData.month,
        year: hashData.base
      }

      const rest = await axiosWebsite.post(`/operation/dirf`, body, config)

      if (rest?.data.length === 0) {
        toast({
          title: 'Retorno',
          description: 'Sem dados para o filtro selecionado!',
          status: 'info',
          duration: 3000,
          isClosable: true
        })
        return null
      }

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const ws = XLSX.utils.json_to_sheet(
        rest?.data?.map(function (e: any) {
          return {
            Beneficiário: e.bene_type,
            País: e.country_name,
            NIF: e.beneficiary_nif,
            'Relação Fonte Pagadora': e.vinculo,
            CNPJ: '',
            'Nome Empresarial': e.client_name,
            Logradouro: e.beneficiary_address,
            Número: '',
            Complemento: '',
            Bairro: '',
            CEP: '',
            Cidade: '',
            Estado: '',
            Telefone: '',
            Data: formatDate(e.reais_closure),
            'Código Receita': String(e.ir.CodDarf).padStart(4, '0'),
            //'Tipo Rendimento': '',
            //'Forma Tributação': '',
            'Valor Pago R$': Number(e.reais_value),
            IRRF: Number(e.ir.VlrRecolhido)
          }
        })
      )

      const colConfig: any[] = [
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        //{ wch: 20 },
        //{ wch: 20 },
        { wch: 20 },
        { wch: 20 }
      ]
      ws['!cols'] = colConfig
      formatColumn(ws, 16, '#,##0.00')
      formatColumn(ws, 17, '#,##0.00')
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(
        data,
        `DIRF-${hashData.month}-${hashData.base}` + fileExtension
      )
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  async function callECFY520 () {
    try {
      if (hidden === true) {
        setDevHidden(false)
      }

      if (!validateFild(hashData, ['customer', 'start_date', 'end_date'])) {
        return toast({
          title: 'Filtro',
          description: 'Todos os campos da consulta são necessarios!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      const body: any = {
        Name: 'ECF_',
        Priority: 'Normal',
        Reference: `${hashData?.customer?.id_syscambio} - ${
          new Date()?.toLocaleString('pt-BR', { timeZone: 'UTC' }).split(',')[0]
        }`,
        SpecificContent: {
          periodo_a: new Date(hashData.start_date)
            ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
            .split(',')[0],
          periodo_b: new Date(hashData.end_date)
            ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
            .split(',')[0],
          cod_cliente: hashData?.customer?.id_syscambio,
          email_solicitante: user.email //'paulo@digitaly.tech'
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite.post(`/queue`, body, config)

      toast({
        title: 'Solicitação',
        description:
          'Sua solicitação foi envia, por favor, aguarde o retorno por e-mail!',
        status: 'success',
        duration: 10000,
        isClosable: true
      })
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  async function callTRF () {
    try {
      if (hidden === true) {
        setDevHidden(false)
      }

      if (!validateFild(hashData, ['customer', 'start_date', 'end_date'])) {
        return toast({
          title: 'Filtro',
          description: 'Todos os campos da consulta são necessarios!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      const body: any = {
        Name: 'tributos_financeiros_queue',
        Priority: 'Normal',
        Reference: `${hashData?.customer?.id_syscambio} - ${
          new Date()?.toLocaleString('pt-BR', { timeZone: 'UTC' }).split(',')[0]
        }`,
        SpecificContent: {
          periodo_a: new Date(hashData.start_date)
            ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
            .split(',')[0],
          periodo_b: new Date(hashData.end_date)
            ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
            .split(',')[0],
          cod_cliente: hashData?.customer?.id_syscambio,
          email_solicitante: user.email //'paulo@digitaly.tech'
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      await axiosWebsite.post(`/queue`, body, config)

      toast({
        title: 'Solicitação',
        description:
          'Sua solicitação foi envia, por favor, aguarde o retorno por e-mail!',
        status: 'success',
        duration: 10000,
        isClosable: true
      })
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  async function votorantim () {
    try {
      if (hidden === true) {
        setDevHidden(false)
      }

      if (!validateFild(hashData, ['customer', 'start_date', 'end_date'])) {
        return toast({
          title: 'Filtro',
          description: 'Todos os campos da consulta são necessarios!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      const body: any = {
        document: hashData.customer.document,
        initial_date: hashData.start_date,
        end_date: hashData.end_date
      }

      const rest = await axiosWebsite.post(`operation/posfec/search`, body)

      if (rest?.data.length === 0) {
        toast({
          title: 'Retorno',
          description: 'Sem dados para o filtro selecionado!',
          status: 'info',
          duration: 3000,
          isClosable: true
        })
        return null
      }

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const final: any[] = rest?.data?.map(function (e: any) {
        return {
          'Cliente - Código': e.client_code,
          Cliente: e.client_name,
          'CNPJ / CPF':
            e.client_document.lenght === 11
              ? formatCPF(e.client_document)
              : formatCNPJ(e.client_document),
          Fechamento: formatDate(e.currency_closure),
          BACEN: e.bacen_number,
          Produto: formatProduct(e.product_type),
          'Inst. Financeira': e.instituicao,
          'Liquidação BACEN': formatDate(e.bacen_closure),
          'Referência Cliente/Operação': e.reference,
          'Moeda estrangeira': `${e.currency_code} - ${getMoedaCompleto(
            e.currency_code
          )}`,
          'Valor - Moeda estrangeira': e.operation_value,
          'Taxa cambial': e.taxa,
          'Valor dos R$': e.reais_value,
          'Liquidação reais': formatDate(e.reais_closure),
          'Natureza da operação': `${e.nature_fact}.${e.nature_client_type}.${e.nature_aval}.${e.nature_beneficiary}.${e.nature_group}`,
          'Descrição Natureza da Operação': e.nat_desc,
          'Pagador / Recebedor': e.beneficiary_name,
          'País - Pagador / Recebedor': `${e.country_initials} - ${e.country_name}`,
          'Relação de vínculo': e.vinculo,
          'Endereço - Pagador/Recebedor': e.beneficiary_address,
          NIF: e.beneficiary_nif,
          'IR - Percentual': e.ir_perc ?? 0,
          'IR - Valor Incidente': e.ir?.VlrIncidente ?? 0,
          IR: e.ir?.VlrRecolhido ?? 0,
          'IR - Código Receita': e.ir?.CodDarf ?? '',
          'PIS - Outros Impostos':
            e.impostos.find((e: any) => e.Tipo === 'PIS')?.Valor ?? 0,
          'COFINS - Outros Impostos':
            e.impostos.find((e: any) => e.Tipo === 'COFINS')?.Valor ?? 0,
          'CIDE - Outros Impostos':
            e.impostos.find((e: any) => e.Tipo === 'CIDE')?.Valor ?? 0,
          'ISS - Outros Impostos':
            e.impostos.find((e: any) => e.Tipo === 'ISS')?.Valor ?? 0,
          'IOF - Outros Impostos':
            e.impostos.find((e: any) => e.Tipo === 'IOF')?.Valor ?? 0
        }
      })
      const ws = XLSX.utils.json_to_sheet(final)
      const key = Object.keys(final[0])
      formatColumn(
        ws,
        key.findIndex((p) => p === 'Valor - Moeda estrangeira'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'Taxa cambial'),
        '#,##0.0000000000'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'Valor dos R$'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'IR - Percentual'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'IR - Valor Incidente'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'IR'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'PIS - Outros Impostos'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'COFINS - Outros Impostos'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'CIDE - Outros Impostos'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'ISS - Outros Impostos'),
        '#,##0.00'
      )
      formatColumn(
        ws,
        key.findIndex((p) => p === 'IOF - Outros Impostos'),
        '#,##0.00'
      )

      let objectMaxLength: any[] = []
      for (let i = 0; i < final.length; i++) {
        let value: any = Object.values(final[i])
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == 'number') {
            objectMaxLength[j] = 15
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j]?.length
                ? objectMaxLength[j]
                : value[j]?.length >= 15
                ? value[j]?.length + 1
                : 20
          }
        }
      }
      const wscols = [
        { width: Number(objectMaxLength[0]) },
        { width: Number(objectMaxLength[1]) },
        { width: Number(objectMaxLength[2]) },
        { width: Number(objectMaxLength[3]) },
        { width: Number(objectMaxLength[4]) },
        { width: Number(objectMaxLength[5]) },
        { width: Number(objectMaxLength[6]) },
        { width: Number(objectMaxLength[7]) },
        { width: Number(objectMaxLength[8]) },
        { width: Number(objectMaxLength[9]) },
        { width: Number(objectMaxLength[10]) },
        { width: Number(objectMaxLength[11]) },
        { width: Number(objectMaxLength[12]) },
        { width: Number(objectMaxLength[13]) },
        { width: Number(objectMaxLength[14]) },
        { width: Number(objectMaxLength[15]) },
        { width: Number(objectMaxLength[16]) },
        { width: Number(objectMaxLength[17]) },
        { width: Number(objectMaxLength[18]) },
        { width: Number(objectMaxLength[19]) },
        { width: Number(objectMaxLength[20]) },
        { width: Number(objectMaxLength[21]) },
        { width: Number(objectMaxLength[22]) },
        { width: Number(objectMaxLength[23]) },
        { width: Number(objectMaxLength[24]) },
        { width: Number(objectMaxLength[25]) },
        { width: Number(objectMaxLength[26]) },
        { width: Number(objectMaxLength[27]) }
      ]

      ws['!cols'] = wscols
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(
        data,
        `operacoes-${hashData.start_date}-${hashData.end_date}` + fileExtension
      )
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  if (isLoading)
    return (
      <CardPage title={'Dados do Contrato (Fonte SYSCambio)'}>
        <Progress isIndeterminate colorScheme={'secondary'} h={1} />
      </CardPage>
    )

  return (
    <CardPage
      title={
        mode === 'one'
          ? 'Relatório de Pagadores e Recebedores'
          : mode === 'two'
          ? 'Relatório de Notas Fiscais'
          : mode === 'three'
          ? 'Relatório de Operações'
          : mode === 'four'
          ? 'Informe de Redimentos'
          : mode === 'five'
          ? 'Pagamento Antecipado – Importação/Exportação.'
          : mode === 'six'
          ? 'Relatório de Volumetria de Contratos'
          : mode === 'seven'
          ? 'Relatório DIRF'
          : mode === 'eight'
          ? 'Relatório pagamentos/Recebimentos do Exterior ou de Não residentes (ECF Y520)'
          : mode === 'nine'
          ? 'Relatório de Operações'
          : mode === 'ten'
          ? 'Tributos de Remessas Financeiras'
          : 'Relatórios'
      }
      _headerRight={
        <VStack spacing={4} align='stretch'>
          <Box w={'300px'} h='40px' mb={8} mt={3}>
            <SimpleFormGenerator
              rows={[
                {
                  columns: 1,
                  fields: [
                    {
                      type: 'select',
                      label: 'Relatórios',
                      name: 'select',
                      options: comboList()
                    }
                  ]
                }
              ]}
              value={modeObj}
              onChange={(v) => {
                setModeObj({ select: v.select })
                setMode(v.select)
              }}
            />
          </Box>
        </VStack>
      }
    >
      <Col px={2} w={'99.2%'}>
        {mode === 'one' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 2,
                fields: [
                  // {
                  //     type: "text",
                  //     label: "Cod. Cliente",
                  //     name: "customer.id_syscambio",
                  //     readOnly: true,
                  // },
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }
              redirect(nextParams)
            }}
          />
        ) : mode === 'two' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 2,
                fields: [
                  // {
                  //     type: "text",
                  //     label: "Cod. Cliente",
                  //     name: "customer.id_syscambio",
                  //     readOnly: true,
                  // },
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  }
                ]
              },
              {
                columns: 4,
                fields: [
                  {
                    type: 'select',
                    label: 'Tipo de Notas',
                    name: 'note_type',
                    options: [
                      {
                        label: () => 'Corretagem',
                        value: 'C'
                      },
                      {
                        label: () => 'Serviço',
                        value: 'S'
                      }
                    ]
                  },
                  {
                    type: 'select',
                    label: 'Período de',
                    name: 'data_type',
                    options: [
                      {
                        label: () => 'Emissão',
                        value: 'E'
                      },
                      {
                        label: () => 'Vencimento',
                        value: 'V'
                      }
                    ]
                  },
                  {
                    type: 'date',
                    label: 'Data Inicial',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'Data Final',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              redirect(nextParams)
            }}
          />
        ) : mode === 'four' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  {
                    type: 'select',
                    label: 'Modelo do Informe',
                    name: 'type_inf',
                    options: [
                      {
                        label: () => 'Corretora',
                        value: 'Corretora'
                      },
                      {
                        label: () => 'Receita',
                        value: 'Receita'
                      }
                    ]
                  },
                  {
                    type: 'select',
                    label: 'Ano Base',
                    name: 'base',
                    options: [
                      {
                        label: () => '2023',
                        value: '2023'
                      },
                      {
                        label: () => '2022',
                        value: '2022'
                      },
                      {
                        label: () => '2021',
                        value: '2021'
                      },
                      {
                        label: () => '2020',
                        value: '2020'
                      },
                      {
                        label: () => '2019',
                        value: '2019'
                      },
                      {
                        label: () => '2018',
                        value: '2018'
                      }
                    ]
                  },
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              if (hidden === false) {
                setDevHidden(true)
              }
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              redirect(nextParams)
            }}
          />
        ) : mode === 'five' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  {
                    type: 'select',
                    label: 'Tipo',
                    name: 'type',
                    options: [
                      {
                        label: () => 'Exportação',
                        value: 'exp'
                      },
                      {
                        label: () => 'Importação',
                        value: 'imp'
                      }
                    ]
                  },
                  {
                    type: 'select',
                    label: 'Tipo de Exportação',
                    name: 'type_export',
                    options: [
                      {
                        label: () => 'PDF',
                        value: 'PDF'
                      },
                      {
                        label: () => 'CSV',
                        value: 'CSV'
                      }
                    ]
                  },
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  }
                ]
              },
              {
                columns: 4,
                fields: [
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              if (hidden === false) {
                setDevHidden(true)
              }
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              redirect(nextParams)
            }}
          />
        ) : mode === 'six' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  },
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              if (hidden === false) {
                setDevHidden(true)
              }
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              redirect(nextParams)
            }}
          />
        ) : mode === 'seven' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  },
                  {
                    type: 'select',
                    label: 'Mês',
                    name: 'month',
                    options: [
                      {
                        label: () => '01',
                        value: 0
                      },
                      {
                        label: () => '02',
                        value: 1
                      },
                      {
                        label: () => '03',
                        value: 2
                      },
                      {
                        label: () => '04',
                        value: 3
                      },
                      {
                        label: () => '05',
                        value: 4
                      },
                      {
                        label: () => '06',
                        value: 5
                      },
                      {
                        label: () => '07',
                        value: 6
                      },
                      {
                        label: () => '08',
                        value: 7
                      },
                      {
                        label: () => '09',
                        value: 8
                      },
                      {
                        label: () => '10',
                        value: 9
                      },
                      {
                        label: () => '11',
                        value: 10
                      },
                      {
                        label: () => '12',
                        value: 11
                      }
                    ]
                  },
                  {
                    type: 'select',
                    label: 'Ano',
                    name: 'base',
                    options: [
                      {
                        label: () => '2023',
                        value: '2023'
                      },
                      {
                        label: () => '2022',
                        value: '2022'
                      },
                      {
                        label: () => '2021',
                        value: '2021'
                      },
                      {
                        label: () => '2020',
                        value: '2020'
                      },
                      {
                        label: () => '2019',
                        value: '2019'
                      },
                      {
                        label: () => '2018',
                        value: '2018'
                      }
                    ]
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              if (hidden === false) {
                setDevHidden(true)
              }
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              redirect(nextParams)
            }}
          />
        ) : mode === 'eight' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  },
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              if (hidden === false) {
                setDevHidden(true)
              }
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              redirect(nextParams)
            }}
          />
        ) : mode === 'nine' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  },
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              if (hidden === false) {
                setDevHidden(true)
              }
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              redirect(nextParams)
            }}
          />
        ) : mode === 'ten' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 4,
                fields: [
                  createCustomerSearchPicker(
                    user.company_id ?? user.id!,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'corporate_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  },
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              if (hidden === false) {
                setDevHidden(true)
              }
              const { corporate_name, document, id_syscambio } =
                v.customer || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  corporate_name,
                  document,
                  id_syscambio
                }
              }

              redirect(nextParams)
            }}
          />
        ) : (
          <Col mt={6}>
            <Alert status='warning'>
              <AlertIcon />
              Selecione um dos Relatórios:
            </Alert>
          </Col>
        )}
        {mode === 'one' ? (
          <Button
            variant={'outline'}
            color={'primary.400'}
            colorScheme={'primary'}
            onClick={() => {
              exportPayerReceiver(hashData?.customer?.id_syscambio)
            }}
          >
            Gerar Relatório de Pagadores e Recebedores
          </Button>
        ) : mode === 'two' ? (
          <Button
            variant={'outline'}
            color={'primary.400'}
            colorScheme={'primary'}
            onClick={() => {
              exportFiscalNotes(hashData)
            }}
          >
            Gerar Relatório de Notas Fiscais
          </Button>
        ) : mode === 'four' ? (
          <>
            <Col mb={6}>
              <p>
                Atenção, esse relatório será enviado para o e-mail
                <b> {user.email} </b>
                assim que for concluído o processo de geração, caso não receba o
                mesmo, entre em contato com a Dascam para uma verificação.
              </p>
            </Col>
            <Button
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => {
                callIncomeReport()
              }}
            >
              Solicitar Informe de Redimentos
            </Button>
          </>
        ) : mode === 'five' ? (
          <>
            <Col mb={6}>
              <p>
                Atenção, esse relatório será enviado para o e-mail
                <b> {user.email} </b>
                assim que for concluído o processo de geração, caso não receba o
                mesmo, entre em contato com a Dascam para uma verificação.
              </p>
            </Col>
            <Button
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => {
                callImpExp()
              }}
            >
              Solicitar Relatório
            </Button>
          </>
        ) : mode === 'six' ? (
          <>
            <Button
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => {
                callVolumatric()
              }}
            >
              Gerar Relatório
            </Button>
          </>
        ) : mode === 'seven' ? (
          <>
            <Button
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => {
                callDirf()
              }}
            >
              Gerar Relatório
            </Button>
          </>
        ) : mode === 'eight' ? (
          <>
            <Col mb={6}>
              <p>
                Atenção, esse relatório será enviado para o e-mail
                <b> {user.email} </b>
                assim que for concluído o processo de geração, caso não receba o
                mesmo, entre em contato com a Dascam para uma verificação.
              </p>
            </Col>
            <Button
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => {
                callECFY520()
              }}
            >
              Solicitar Relatório
            </Button>
          </>
        ) : mode === 'nine' ? (
          <>
            <Button
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => {
                votorantim()
              }}
            >
              Gerar Relatório
            </Button>
          </>
        ) : mode === 'ten' ? (
          <>
            <Col mb={6}>
              <p>
                Atenção, esse relatório será enviado para o e-mail
                <b> {user.email} </b>
                assim que for concluído o processo de geração, caso não receba o
                mesmo, entre em contato com a Dascam para uma verificação.
              </p>
            </Col>
            <Button
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => {
                callTRF()
              }}
            >
              Solicitar Tributos de Remessas Financeiras
            </Button>
          </>
        ) : (
          <></>
        )}
      </Col>
    </CardPage>
  )
}

export default ListReportsPage
