import { Badge, Box, Flex, Text } from '@chakra-ui/react'
import { axiosWebsite } from 'helpers/axiosInstances'
import { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { GlobalState } from 'redux/types'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

const Home = (): JSX.Element => {
  const user = useSelector((state: GlobalState) => state.user)
  const [tableCoin, setTableCoin] = useState<any[]>([])
  const [coinValue, setCoinValue] = useState<number>(0)
  const [valueConvert, setValueConvert] = useState<number>(0)
  const [coin, setCoin] = useState<string>('USD-BRL')
  const [coinName, setCoinName] = useState<string>('')
  const [cards, setCard] = useState<any>()

  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (tableCoin.length !== 0 && coin) {
      setCoinName(
        tableCoin.filter((e) => {
          return `${e.codigoInicio}-${e.codigoFim}` === coin
        })[0].nome
      )
    }
  }, [tableCoin, coin])

  async function getData () {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const { data } = await axiosWebsite.get(`/get-table-coin`, config)
      setTableCoin(data)
    } catch (error) {}
  }

  async function getDataCoinConvert (
    codigoInicio: string,
    codigoFim: string,
    value: number
  ) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const { data } = await axiosWebsite.post(
        `/get-coin`,
        {
          codigoInicio: codigoInicio,
          codigoFim: codigoFim,
          valor: value
        },
        config
      )
      if (data.valor) {
        setValueConvert(data.valor)
      } else {
        setValueConvert(0)
      }
    } catch (error) {}
  }

  const columns: TableColumn<any>[] = [
    {
      name: 'id',
      selector: (row) => row.nome ?? '',
      sortable: true,
      omit: true
    },
    {
      name: 'Código',
      selector: (row) => `${row.codigoInicio} / ${row.codigoFim}`,
      sortable: true,
      style: {
        fontWeight: 'bolder'
      }
    },
    {
      name: 'Compra',
      selector: (row) => row.compra?.replace('.', ',') ?? '',
      sortable: true
    },
    {
      name: 'Venda',
      selector: (row) => row.venda?.replace('.', ',') ?? '',
      sortable: true
    },
    {
      name: 'Máxima',
      selector: (row) => row.maxima?.replace('.', ',') ?? '',
      sortable: true
    },
    {
      name: 'Mínima',
      selector: (row) => row.minima?.replace('.', ',') ?? '',
      sortable: true
    },
    {
      name: 'Var.',
      selector: (row) => row.variacao?.replace('.', ',') ?? '',
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => !String(row.variacao ?? '').includes('-'),
          style: {
            color: 'green',
            fontWeight: 'bolder'
          }
        },
        {
          when: (row) => String(row.variacao ?? '').includes('-'),
          style: {
            color: 'firebrick',
            fontWeight: 'normal',
            fontStyle: 'italic'
          }
        }
      ]
    },
    {
      name: 'Var. %',
      selector: (row) => row.variacaoPorcentagem?.replace('.', ',') ?? '',
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => !String(row.variacaoPorcentagem ?? '').includes('-'),
          style: {
            color: 'green',
            fontWeight: 'bolder'
          }
        },
        {
          when: (row) => String(row.variacaoPorcentagem ?? '').includes('-'),
          style: {
            color: 'firebrick',
            fontWeight: 'lighter',
            fontStyle: 'italic'
          }
        }
      ]
    },
    {
      name: 'Hora',
      selector: (row) => row.horario ?? '',
      sortable: true
    }
  ]

  function cardValue (tipo: string, valor: string) {
    return (
      <Box
        maxW='36'
        minW='36'
        borderRadius='lg'
        shadow={'md'}
        m={4}
        overflow='hidden'
        backgroundColor={'white'}
      >
        <Box p='3'>
          <Box display='flex' alignItems='baseline'>
            <Badge
              w={40}
              borderRadius='full'
              px='2'
              backgroundColor='primary.500'
            >
              <Text
                align={'center'}
                fontSize={'md'}
                color={'white'}
                fontWeight={'bold'}
              >
                {tipo}
              </Text>
            </Badge>
          </Box>

          <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight'>
            <Text
              as={'span'}
              fontSize={'md'}
              color={'GrayText'}
              fontWeight={'bold'}
              mr={1}
            >
              R$
            </Text>
            <Text
              as={'span'}
              fontSize={'lg'}
              color={'black'}
              fontWeight={'extrabold'}
            >
              {valor}
            </Text>
          </Box>
        </Box>
      </Box>
    )
  }

  function createCardValue () {
    try {
      const item = tableCoin.filter((e) => {
        return `${e.codigoInicio}-${e.codigoFim}` === coin
      })
      if (item) {
        return (
          <>
            {cardValue('Compra', item[0].compra?.replace('.', ','))}
            {cardValue('Venda', item[0].venda?.replace('.', ','))}
            {cardValue('Máxima (Dia)', item[0].maxima?.replace('.', ','))}
            {cardValue('Mínima (Dia)', item[0].minima?.replace('.', ','))}
            {cardValue(
              'Variação %',
              `${item[0].variacaoPorcentagem?.replace('.', ',')}%`
            )}
          </>
        )
      }
    } catch (error) {}
  }

  return (
    <>
      {user?.cad_type === 'JURIDICO' && user.status !== 'ACTIVE' ? (
        <Redirect to='/data/company' />
      ) : null}
      {user?.cad_type === 'FISICO' && user.status !== 'ACTIVE' ? (
        <Redirect to='/data/individual' />
      ) : null}
      {user?.cad_type === 'ESTRANGEIRO' && user.status !== 'ACTIVE' ? (
        <Redirect to='/data/foreign' />
      ) : null}
      {user === undefined ? <Redirect to='/unauthorized' /> : null}
      <Box height={'100vh'} maxH={'100%'}>
        <Box
          flexDirection={'column'}
          flex={1}
          backgroundColor={'whiteAlpha.500'}
          backdropFilter='auto'
          blur={'sm'}
          borderRadius='lg'
          shadow={'md'}
          mr={'10px'}
          ml={'10px'}
          backdropBlur={'md'}
          height={'98%'}
          overflow={'auto'}
        >
          {
            // <iframe
            //   title='a'
            //   src='https://dashboardcotacao.dascam.com.br/#/'
            //   width={'100%'}
            //   height={'100%'}
            // ></iframe>
          }

          <div>
            <Box maxW={'40%'} m={4}>
              <SimpleFormGenerator
                rows={[
                  {
                    columns: 3,
                    fields: [
                      {
                        name: 'coin',
                        label: 'Moedas',
                        type: 'select',
                        options: [
                          { label: () => 'USD para BRL', value: 'USD-BRL' },
                          { label: () => 'EUR para BRL', value: 'EUR-BRL' },
                          { label: () => 'JPY para BRL', value: 'JPY-BRL' },
                          { label: () => 'AUD para BRL', value: 'AUD-BRL' },
                          { label: () => 'CAD para BRL', value: 'CAD-BRL' },
                          { label: () => 'GBP para BRL', value: 'GBP-BRL' }
                        ]
                      },
                      {
                        name: 'value',
                        label: 'Valor',
                        type: 'currency',
                        readOnly: !coin
                      },
                      {
                        name: 'valueConvert',
                        label: 'Valor em Reais',
                        type: 'currency',
                        readOnly: true
                      }
                    ]
                  }
                ]}
                value={{
                  value: coinValue,
                  coin: coin,
                  valueConvert: valueConvert
                }}
                onChange={(data) => {
                  setCoinValue(data.value)
                  setCoin(data.coin)
                  if (data.value && data.coin) {
                    getDataCoinConvert(
                      data.coin.split('-')[0],
                      data.coin.split('-')[1],
                      data.value
                    )
                  }
                }}
              />
            </Box>
            {coin && (
              <Text
                m={4}
                color={'primary.500'}
                fontSize={'2xl'}
                fontWeight={'extrabold'}
              >
                Valor {` - ${coin?.replace('-', ' para ')}`}
                <Text
                  color={'primary.500'}
                  fontSize={'x-small'}
                  fontWeight={'light'}
                >
                  Valor referente a 1 {` ${coinName?.replace('/', ' X ')}`}
                </Text>
              </Text>
            )}

            <Flex>{tableCoin.length !== 0 && coin && createCardValue()}</Flex>
            <Text
              m={4}
              color={'primary.500'}
              fontSize={'2xl'}
              fontWeight={'extrabold'}
            >
              Tabela Geral
            </Text>
            <Flex>
              <Box
                shadow={'md'}
                w={'full'}
                borderWidth='1px'
                borderRadius='lg'
                m={4}
              >
                <DataTable
                  fixedHeader={true}
                  fixedHeaderScrollHeight={`full`}
                  noDataComponent=''
                  columns={columns}
                  data={tableCoin}
                  dense={true}
                  striped={true}
                />
              </Box>
            </Flex>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default Home
